import PropTypes from 'prop-types';
import getAttr from '../../utils/getAttr';
import MouseOnlyLink from '../MouseOnlyLink';
import { getHearingURL, isPublic } from '../../utils/hearing';
import Link from '../LinkWithLang';
import { FormattedMessage } from 'react-intl';
import Icon from '../../utils/Icon';
import LabelList from '../LabelList';
import config from '../../config';
import React from 'react';
import { withRouter } from 'react-router-dom';

// eslint-disable-next-line import/no-unresolved
import defaultImage from '@city-images/default-image.svg';
import HearingJoinRequestInfo from './HearingJoinRequestInfo';
import nl2br from 'react-nl2br';
import HearingInformationJoinRequests from './HearingInformationJoinRequests';
import HearingCardInformation from './HearingCardInformation';
import NewTabLink from '../../common/NewTabLink';
import HeadingVariable from '../../common/HeadingVariable';

const HearingListItem = ({ 
  hearing,
  language,
  history,
  listId,
  headingLevel = 3 
}) => {
  const getFinalStatementPreviewURL = () => {
    return '/statement/preview/' + hearing.final_statement.id;
  };

  const mainImage = hearing.main_image;

  let mainImageStyle = {
    width: '300px',
    aspectRatio: '4/3',
    backgroundImage: `url(${defaultImage})`,
  };

  if (hearing.main_image) {
    mainImageStyle = {
      ...mainImageStyle,
      backgroundImage: 'url("' + mainImage.url + '")',
    };
  }

  const translationAvailable = !!getAttr(hearing.title, language, { exact: true });
  const availableInLanguageMessages = {
    fi: 'Kuuleminen saatavilla suomeksi',
    sv: 'Hörandet tillgängligt på svenska',
    en: 'Questionnaire available in English',
  };
  return (
    <div className="hearing-list-item" role="listitem">
      <div className="hearing-list-item-image">
        <MouseOnlyLink
          className="hearing-card-image"
          style={mainImageStyle}
          history={history}
          url={getHearingURL(hearing)}
          altText={getAttr(hearing.title, language)}
        />
      </div>
      <div className="hearing-list-item-content">
        <HeadingVariable level={headingLevel} className="h4 hearing-list-item-title">
          <Link to={{ path: getHearingURL(hearing) }}>
            {!isPublic(hearing) ? (
              <FormattedMessage id="hearingListNotPublished">
                {(label) => <Icon name="eye-slash" aria-label={label} />}
              </FormattedMessage>
            ) : null}{' '}
            {getAttr(hearing.title, language)}
          </Link>
        </HeadingVariable>
        <div>
          <p className="hearing-organization">{hearing.organization}</p>
          {!translationAvailable && (
            <div className="hearing-card-notice">
              <Icon name="exclamation-circle" aria-hidden="true" />
              <div>
              <FormattedMessage id="hearingTranslationNotAvailable" />
              {config.languages.map(
                lang =>
                  (getAttr(hearing.title, lang, { exact: true }) ? (
                    <div key={lang} className="language-available-message" lang={lang}>
                      {availableInLanguageMessages[lang]}
                    </div>
                  ) : null),
              )}
              </div>
            </div>
          )}
          <p>
            { nl2br( getAttr(hearing.abstract, language) ) }
          </p>
          <LabelList labels={hearing.labels} className="hearing-list-item-labellist"
                      language={language} />
        </div>
        <HearingCardInformation hearing={hearing} />
        
        {hearing.final_statement && hearing.final_statement.published &&
          <NewTabLink 
            to={getFinalStatementPreviewURL()} 
            textId="finalStatementPreview" 
            className="final-statement-preview-link"
          />
        }
        
        {hearing.can_access_detail && hearing.active_join_requests_num > 0 &&
          <HearingInformationJoinRequests hearing={hearing} history={history} />
        }
      </div>
      <div className="hearing-list-item-footer">
        <HearingJoinRequestInfo hearing={hearing} listId={listId} />
      </div>
    </div>
  );
};
HearingListItem.propTypes = {
  hearing: PropTypes.object.isRequired,
  language: PropTypes.string,
  history: PropTypes.object.isRequired,
  listId: PropTypes.string,
  headingLevel: PropTypes.number,
};

export default withRouter(HearingListItem);