import Icon from "../utils/Icon";
import getMessage from "../utils/getMessage";

/**
 * For any links (any href) that open in a new tab. Does not modify href.
 */
const NewTabA = ({href, className, children}) => {
  const newTabText = getMessage('linkOpensInANewTab')
  
  return (
    <a href={href} target="_blank" rel="noopener noreferrer" className={["newtablink", className].join(" ")}>
      {children}
      <Icon name="external-link" title={newTabText} aria-hidden="true" />
      <span className="sr-only">({newTabText})</span>
    </a>
  )
}

export default NewTabA;