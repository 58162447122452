import PropTypes from 'prop-types';
import React from 'react';
import NewTabA from '../../../../../common/NewTabA';
import getMessage from '../../../../../utils/getMessage';

const CommentImageComponent = ({ images }) => {
  return (
    <div className="hearing-comment__images">
      {images
        ? images.map(image => (
          // eslint-disable-next-line react/jsx-indent
          <NewTabA key={image.url} href={image.url} className="hearing-comment-images-image">
            <img
              alt={getMessage('userAddedImageAlt')}
              src={image.url}
              width={image.width < 100 ? image.width : 100}
              height={image.height < 100 ? image.height : 100}
            />
          </NewTabA>
        ))
        : null}
    </div>
  );
};

CommentImageComponent.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object),
};

export default CommentImageComponent;