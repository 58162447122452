import { FormattedMessage } from "react-intl";
import Link from "../components/LinkWithLang";
import Icon from "../utils/Icon";
import getMessage from "../utils/getMessage";

/**
 * For frontend links that open in a new tab. Adds the active lang= parameter to the url.
 */
const NewTabLink = ({to = '/debug', textId, className}) => {
  const newTabText = getMessage('linkOpensInANewTab')
  return (
    <Link to={{ path: to }} target="_blank" className={["newtablink",className].join(" ")}>
      <FormattedMessage id={textId} />
      <Icon name="external-link" title={newTabText} aria-hidden="true" />
      <span className="sr-only">({newTabText})</span>
    </Link>
  )
}

export default NewTabLink;