import classnames from "classnames";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage, FormattedRelative } from "react-intl";
import { DateTime } from 'luxon';


const CommentAuthorAndDate = ({
  isOwnComment,
  isAuthorAdminUser,
  commentData,
}) => {

  /**
   * User DateTime to convert current timestamp to desired format.
   * @returns {String}
   */
  const parseTimestamp = (timestamp) => DateTime.fromISO(timestamp).toFormat('dd.MM.yyyy HH:mm');

  const dateTooltip =(
    <Tooltip id="comment-date-tooltip">
      {parseTimestamp(commentData.created_at)}
    </Tooltip>
  );

  return (
    <div className='hearing-comment-author-and-date'>
      <address
        className={classnames(
          'hearing-comment-author',
          {
          'hearing-comment-author-own': isOwnComment,
        })}
      >
        {isAuthorAdminUser && (
          <div className='hearing-comment-author-organization'>
              {commentData.organization}<span aria-hidden="true">:&nbsp;</span>
          </div>
        )}
        <div>
          {commentData.author_name || <FormattedMessage id="anonymous" />}
          {isOwnComment && <span className="sr-only"><FormattedMessage id="ownComment" /></span> }
        </div>
      </address>
      <OverlayTrigger
        placement="top"
        overlay={dateTooltip}
        delayShow={300}
      >
        <time className="hearing-comment-date">
          <FormattedRelative value={commentData.created_at} />
        </time>
      </OverlayTrigger>
    </div>
  )
}

export default CommentAuthorAndDate;