import getMessage from "../../../../../../utils/getMessage";

const CommentUserIcon = ({authorRole, userSeesContent, icon}) => {

  let frameClass;
  let definedIcon;
  if (userSeesContent) {
    if (icon) {
      switch(icon) {
        case 'system': 
          definedIcon = (
            <img src="/assets/images/digiraati-logo-fi.png" style={{
              height: '1em', width: '1em', objectFit: 'cover', objectPosition: 'left'}} />
          ); 
          break;
        default: definedIcon = icon;
      }
      frameClass = '';
    } else {
      switch (authorRole) {
        case 'admin': definedIcon = '👑'; break;
        default: definedIcon = '😀'; break;
      }
      frameClass = `${authorRole}-icon`;
    }
  } else {
    definedIcon = ' ';
    frameClass = 'hidden-icon'
  }

  return (
    <div style={{display: 'flex', placeItems: 'center'}} aria-label={getMessage(authorRole)}>
      <div className={`hearing-comment-user-icon ${frameClass}`} aria-hidden="true">
        <div>
          <span>{definedIcon}</span>
        </div>
      </div>
    </div>
  )
}

export default CommentUserIcon;