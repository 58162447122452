import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import LoadSpinner from './components/LoadSpinner';
import config from '../src/config';
import UserInfoPage from './components/user/UserInfoPage';
import { getUser } from './store/selectors/user';
import { connect } from 'react-redux';
import { OIDCCallBack } from './components/OIDCCallBack';

const HomeContainer = lazy(() => import(
  /* webpackChunkName: "home" */ './views/Home'));
const Info = lazy(() => import(
  /* webpackChunkName: "info" */ './views/md/Info'));
const Participate = lazy(() => import(
  /* webpackChunkName: "participate" */ './views/md/Participate'));
const HostInstructions = lazy(() => import(
  /* webpackChunkName: "host_instructions" */ './views/md/HostInstructions'));
const TermsOfUseAndModeration = lazy(() => import(
  /* webpackChunkName: "host_instructions" */ './views/md/TermsOfUseAndModeration'));
const HearingCreationInstructions = lazy(() => import(
    /* webpackChunkName: "hearing_creation_instructions" */ './views/md/HearingCreationInstructions'));
const AccessibilityInfo = lazy(() => import(
  /* webpackChunkName: "accessibility_info" */ './views/md/AccessibilityInfo'));
const PrivacyInfo = lazy(() => import(
  /* webpackChunkName: "privacy_info" */ './views/md/PrivacyInfo'));
const HearingsContainer = lazy(() => import(
  /* webpackChunkName: "hearings" */'./views/Hearings/Hearings'));
const HearingContainer = lazy(() => import(
  /* webpackChunkName: "hearing" */'./views/Hearing/HearingContainer'));
const NewHearingContainer = lazy(() => import(
  /* webpackChunkName: "newhearing" */'./views/NewHearing/NewHearingContainer'));
const FullscreenHearingContainer = lazy(() => import(
  /* webpackChunkName: "fullscreen" */'./views/FullscreenHearing/FullscreenHearingContainer'));
const Login = lazy(() => import(
  /* webpackChunkName: "auth" */'./views/Auth/Login'));
const Registration = lazy(() => import(
  /* webpackChunkName: "auth" */'./views/Auth/Registration'));
const PasswordReset = lazy(() => import(
  /* webpackChunkName: "auth" */'./views/Auth/PasswordReset'));
const PasswordResetConfirm = lazy(() => import(
  /* webpackChunkName: "auth" */'./views/Auth/PasswordResetConfirm'));
const RegistrationSuccess = lazy(() => import(
  /* webpackChunkName: "auth" */'./views/Auth/RegistrationSuccess'));
const Confirm = lazy(() => import(
  /* webpackChunkName: "auth" */'./views/Auth/Confirmation'));
const ConfirmSuccess = lazy(() => import(
  /* webpackChunkName: "auth" */'./views/Auth/ConfirmationSuccess'));
const JoinHearing = lazy(() => import(
  /* webpackChunkName: "hearings" */'./views/Hearings/JoinHearing'));
const JoinHearingFacilitator = lazy(() => import(
  /* webpackChunkName: "hearings" */'./views/Hearings/JoinHearingFacilitator'));
const FinalStatement = lazy(() => import(
  /* webpackChunkName: "finalstatement" */'./views/Hearings/FinalStatement'));
const FinalStatementPreview = lazy(() => import(
  /* webpackChunkName: "finalstatementPreview" */'./views/Hearings/FinalStatementPreview'));

/* Vanilla Redirect component can't handle dynamic rerouting,
 * so we need Redirector to access params for the hearingSlug
 */
const Redirector = ({ match }) => {
  return (
    <div>
      <Redirect to={{ path: '/' + match.params.hearingSlug }} />
    </div>
  );
};

Redirector.propTypes = {
  match: PropTypes.object,
};



const Routes = ({
                  user,
                }) => {
  const token = localStorage.getItem('token');
  return (
    <Suspense fallback={<LoadSpinner />}>
      <Switch>
        <Route exact path="/" render={props => <HomeContainer {...props} />} />
        <Route path="/user-info">
          {!user && !token ? <Redirect to="/" /> : <UserInfoPage />}
        </Route>
        <Route path="/auth/login" render={props => <Login {...props} />} />
        <Route path="/auth/registration/success"
               render={props => <RegistrationSuccess {...props} />} />
        <Route path="/auth/registration" render={props => <Registration {...props} />} />
        <Route path="/password-reset/confirm/:uid/:token"
               render={props => <PasswordResetConfirm {...props} />} />
        <Route path="/password-reset" render={props => <PasswordReset {...props} />} />
        <Route path="/auth/confirm/success" render={props => <ConfirmSuccess {...props} />} />
        <Route path="/auth/confirm/:confirmationKey" render={props => <Confirm {...props} />} />
        <Route path="/info" render={props => <Info {...props} />} />
        {config.showAccessibilityInfo && (
          <Route path="/accessibility" render={props => <AccessibilityInfo {...props} />} />
        )}
        <Route path="/oidc/callback/" render={props => <OIDCCallBack {...props} />} />
        <Route path="/privacy" render={props => <PrivacyInfo {...props} />} />
        <Route path="/participate" render={props => <Participate {...props} />} />
        <Route path="/host-instructions" render={props => <HostInstructions {...props} />} />
        <Route path="/hearing-creation-instructions" render={props => <HearingCreationInstructions {...props} />} />
        <Route path="/terms-of-use-and-moderation" render={props => <TermsOfUseAndModeration {...props} />} />
        <Route path="/hearings/:tab" render={props => <HearingsContainer {...props} />} />
        <Route path="/hearing/new" render={props => <NewHearingContainer {...props} />} />
        <Route path="/hearing/:hearingSlug" render={props => <Redirector {...props} />} />
        <Route path="/:hearingSlug/comments" render={props => <HearingContainer {...props} />} />
        <Route path="/:hearingSlug/join/:joinToken" render={props => <JoinHearing {...props} />} />
        <Route path="/:hearingSlug/join_facilitator/:joinToken"
               render={props => <JoinHearingFacilitator {...props} />} />
        <Route path="/statement/preview/:statementId"
               render={props => <FinalStatementPreview {...props} />} />
        <Route path="/:hearingId/statement/" render={props => <FinalStatement {...props} />} />
        <Route path="/:hearingSlug/fullscreen"
               render={props => <FullscreenHearingContainer {...props} />} />
        <Route path="/:hearingSlug/manage" render={props => <HearingContainer {...props} />} />
        <Route path="/:hearingSlug/edit" render={props => <HearingContainer {...props} />} />
        <Route path="/:hearingSlug/finalstatement"
               render={props => <HearingContainer {...props} />} />
        <Route path="/:hearingSlug/materials" render={props => <HearingContainer {...props} />} />
        <Route exact path="/:hearingSlug" render={props => <HearingContainer {...props} />} />


      </Switch>
    </Suspense>
  );
};

const mapStateToProps = (state) => ({
  user: getUser(state),
});


Routes.propTypes = {
  user: PropTypes.object,
};
export default withRouter(connect(mapStateToProps)(Routes));
