import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Waypoint } from 'react-waypoint';
import LoadSpinner from '../LoadSpinner';
import HearingListItem from '../Hearing/HearingListItem';
import { FormattedMessage } from 'react-intl';

const ListWrapperDiv = styled.div`
    font: normal normal normal 18px/24px PT Sans;

    *.hearing-list-item {
        margin-bottom: 20px;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.33);
    }

    .hearing-list-item-content {
        max-width: 100%;
    }
    .activeJoinRequestNotifications{
        display: none;
    }
`;

const UserSummaryHearingListPanel = ({ list = [], language, handleReachBottom, isLoading, header }) => {

  if (list.length === 0) {
    return (
      <div className="hearing-list--empty">
        <h3>{header}</h3>
        <p>
          <FormattedMessage id='noHearings'/>
        </p>
      </div>
    )
  }

  return (
    <div>
      <h3>{header}</h3>
    <ListWrapperDiv className="hearing-list">
      {isLoading
        ? <LoadSpinner />
        : <>
          <div role="list">
            {list.map(hearing => (
              <HearingListItem hearing={hearing} key={hearing.id} language={language} headingLevel={4} />
            ))}
          </div>
          <Waypoint onEnter={handleReachBottom} />
        </>}

    </ListWrapperDiv>
    </div>
  );
};

const mapStateToProps = (state) => ({
  language: state.language,
});

UserSummaryHearingListPanel.propTypes = {
  language: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool.isRequired,
};
export default connect(mapStateToProps)(UserSummaryHearingListPanel);