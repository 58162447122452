import { Button, NavItem } from 'react-bootstrap';
import React, { useState } from 'react';
import { GlobalStyles, Menu } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { withRouter } from 'react-router-dom';
import getMessage from '../../utils/getMessage';
import Icon from '../../utils/Icon';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { logout } from '../../utils/loginUtils';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const UserMenu = ({ user, history, language, getNavItem }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onUserInfoClick = () => {
    history.push({
      pathname: '/user-info',
    });
    handleClose();
  };

  const MAX_USERNAME_LENGTH_FOR_BUTTON = 10;
  const usernameSlice = user?.username.slice(0,MAX_USERNAME_LENGTH_FOR_BUTTON)

  return (
    <React.Fragment
      key="user-menu-fragment"
    >
      <GlobalStyles
        styles={{ font: 'normal normal bold 18px PT Sans' }}
      />
      <Button
        bsClass="user-menu-button"
        id="user-menu-button"
        role="button"
        aria-haspopup="true"
        aria-expanded={open}
        aria-label={`${getMessage('userMenuLabel')}: ${usernameSlice}`}
        title={getMessage('userMenuLabel')}
        onClick={handleClick}
      >
        <div className={classNames('user-menu-button--bg', {'user-menu-button__open': open})}>
          <div className="user-menu-button--ball" aria-hidden="true">
            <div style={{position: 'absolute', width: '100%', height: '100%', top: '0', left: '0'}}>
              <div style={{position: 'absolute',top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                {user?.username?.charAt(0).toUpperCase()}
              </div>
            </div>
          </div>
          <div className="user-menu-button--username">
            {usernameSlice}
          </div>
        </div>
      </Button>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          key={getMessage('userInfoLabel')}
          style={{ font: 'normal normal 14px PT Sans' }}
          onClick={onUserInfoClick}
        >
          <div>
            {user.nickname}<br />{user.email}
            {user.adminOrganizations.map((org) => (
            <div key={`usermenuOrg${org}`}>
              {org}
            </div>
            ))}
          </div>
        </MenuItem>
        <MenuItem
          key={getMessage('ownProfile')}
          style={{ font: 'normal normal bold 16px PT Sans' }}
          aria-label={getMessage('userInfoPageHeader')}
          role="link"
          onClick={onUserInfoClick}
        >
          <Icon
            name="user"
            style={{ marginRight: '10px' }}
          />
          {getMessage('userInfoPageHeader')}
        </MenuItem>

        <MenuItem
          key={getMessage('logout')}
          style={{ font: 'normal normal bold 16px PT Sans' }}
          aria-label={getMessage('logout')}
          role="button"
          onClick={() => logout(language)}
        >
          <Icon
            name="sign-out"
            style={{ marginRight: '10px' }}
          /> {getMessage('logout')}
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
};

UserMenu.propTypes = {
  user: PropTypes.object,
  language: PropTypes.string,
  history: PropTypes.object,
  getNavItem: PropTypes.func,
};

export default withRouter(UserMenu);