import { getUser } from '/src/store/selectors/user';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import getMessage from '/src/utils/getMessage';
import { generateRandomString } from '/src/utils/common';
import styled from 'styled-components';
import ProfileImage from './ProfileImage';
import UserUpdateModal from './UserUpdateModal';


const ImageWrapperDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 20px;

    @media (max-width: 610px) {
        justify-content: center;
        flex-direction: row;
        margin-right: 0;
    }
`;

const MainStyledDiv = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font: normal normal normal 16px PT Sans;

    @media (max-width: 610px) {
        flex-direction: column;
    }
`;


const UserMainInfoPanel = ({ user }) => {
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

  const info = [
    {
      name: getMessage('username'),
      value: user?.username ?? '',
    },
    {
      name: getMessage('email'),
      value: user?.email ?? '',
    },
  ];

  const addUserAdminOrganizationsToInfoTableIfExists = () => {
    if (user?.adminOrganizations?.length > 0) {
      info.push({
        name: getMessage('adminOrganizations'),
        value: user?.adminOrganizations?.join(',') ?? '',
      })
    }
  }
  addUserAdminOrganizationsToInfoTableIfExists();

  const addUserOidToInfoTableIfExists = () => {
    // Duplicate check likely unnecessary, but just in case
    if (user?.oid && !info.find( row => row.name == 'OID' )) {
      info.push({
        name: 'OID',
        value: user.oid,
      });
    }
  };
  addUserOidToInfoTableIfExists();

  return (
    <div>
      <h2><FormattedMessage id="userMainInfoTab" /></h2>
      <MainStyledDiv>
        <ImageWrapperDiv>
          <ProfileImage />
        </ImageWrapperDiv>

        <div>
          <div
            aria-label={getMessage('userMainInfoTab')}
          >
            <dl>
              {info.map(item =>
                <div
                  key={`table_row_${generateRandomString()}`}
                >
                  <dt>{item.name}</dt>
                  <dd>{item.value}</dd>
                </div>,
              )}
            </dl>
            <button 
              style={{marginTop: '1em'}} type="button" className="digi-button"
              onClick={() => {
                setIsUpdateModalOpen(true);
              }}
            >
              <FormattedMessage id="edit" />
              </button>
          </div>
        </div>
        <UserUpdateModal 
          isOpen={isUpdateModalOpen}
          close={() => setIsUpdateModalOpen(false)}
        />

      </MainStyledDiv>
    </div>
  )
    ;
};

const mapStateToProps = (state) => ({
  user: getUser(state),
});

UserMainInfoPanel.propTypes = {
  user: PropTypes.object,
};

export default injectIntl(connect(mapStateToProps)(UserMainInfoPanel));
