import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import LoadSpinner from '../LoadSpinner';
import CommentImageComponent from '../Hearing/sortable-comment-list/comment-list/comment/CommentImageComponent';
import CommentBody from '../Hearing/sortable-comment-list/comment-list/comment/Comment/CommentBody';
import CommentHeaderComponent from '../Hearing/sortable-comment-list/comment-list/comment/CommentHeaderComponent';
import { getUser } from '../../store/selectors/user';
import { data } from 'autoprefixer';
import { FormattedMessage } from 'react-intl';
import LinkWithLang from '../LinkWithLang';

const UserVotedCommentListPanel = ({
                                     list = [],
                                     language,
                                     history,
                                     isLoading,
                                     header,
                                     user,
                                     value,
                                     fetchMore,
                                   }) => {

  useEffect(() => {
    // Remove link elements from focus cycle
    const votedCommentCellList = document.getElementsByClassName('voted-comment-cell');
    for (let i = 0; i < votedCommentCellList.length; i++) {
      const linkLists = votedCommentCellList[i].getElementsByTagName('a');
      for (let j = 0; j < linkLists.length; j++) {
        linkLists[j].setAttribute('tabIndex', '-1');
      }
    }
    const commentVoteActionLinks = document.getElementsByClassName('hearing-comment-vote-link');
    for (let i = 0; i < commentVoteActionLinks.length; i++) {
      commentVoteActionLinks[i].setAttribute('tabIndex', '-1');
    }
  }, []);

  return (
    <div>
      <h3>{header}</h3>
      <ul className="commentlist">
        {list.map((comment) => (
          <li key={`liked_${comment.id}`} className="liked-comment-listitem">
            <div className="hearing-comment">
              <CommentHeaderComponent
                isAuthorAdminUser={false}
                isOwnComment={comment.author_name === user.username}
                commentData={comment}
                isNewComment={false}
                parentIdListLength={comment.root_comment_id ? 1 : 0}
                userSeesContent={!data.details_hidden}
              />
              <CommentBody data={comment} />
              <CommentImageComponent images={comment.images} />
            </div>
            <div>
              <dl style={{display: 'flex', gap:'1em', paddingLeft: '0.5em'}}>
                <dt><FormattedMessage id="hearing" /></dt>
                <dd>
                  {comment.hearing.title[language]
                  ? comment.hearing.title[language]
                  : comment.hearing.title[
                      Object.keys(comment.hearing.title)[0]
                    ]}
                </dd>
              </dl>
            </div>
            <LinkWithLang
              className="digi-button"
              to={{
                path: `/${comment.hearing.slug}/comments`,
                search: `?lang=${language}&focusedComment=${comment.id}`,
              }}
            >
              <FormattedMessage id="goToComment" />
            </LinkWithLang>
          </li>
        ))}
      </ul>
      {isLoading ? (
        <LoadSpinner />
      ) : (
        <div>
          {list.length < value && (
            <button type="button" className="digi-button" onClick={fetchMore}>
              <FormattedMessage id="loadMore" />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  user: getUser(state),
  language: state.language,
});

UserVotedCommentListPanel.propTypes = {
  language: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
export default withRouter(connect(mapStateToProps)(UserVotedCommentListPanel));