import PropTypes from 'prop-types';
import LabelList from '../LabelList';
import React from 'react';

const HearingCardLabels = ({ hearing, language }) => {

  return (
      <div className="hearing-card-topbar"
           style={{ paddingBottom: !hearing.can_access_detail && '0' }}>
        <div>
          <LabelList
            className="hearing-list-item-labellist"
            labels={hearing.labels}
            language={language}
            asLinks={true}
          />
        </div>
      </div>
  );
};

HearingCardLabels.propTypes = {
  hearing: PropTypes.object,
  language: PropTypes.string,
};

export default HearingCardLabels;