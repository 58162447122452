import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import getAttr from '../utils/getAttr';
import Link from './LinkWithLang';
import { injectIntl, intlShape } from 'react-intl';

export const Labels = ({className, intl, labels, language, asLinks = false}) => {

  const labelToHTML = (label) => {
    const tag = (<Fragment><span className="tag">#{getAttr(label.label, language)}</span>{' '}</Fragment>)
    if (asLinks) {
      return (
        <Link
          to={{
            path: '/hearings/list',
            search: `?label=${label.id}&lang=${language}`,
            state: { filteredByLabelLink: true }
          }}
          key={label.id || label}
        >
          {tag}
        </Link>
      );
    }
    return tag;
  };

  const ariaLabel = intl.formatMessage({id: 'labelListTagsTitle'});

  return (
    <div className={className} aria-label={ariaLabel}>
      {labels.map((label) => labelToHTML(label))}
    </div>
  );
  
}

Labels.propTypes = {
  intl: intlShape,
  labels: PropTypes.array,
  className: PropTypes.string,
  language: PropTypes.string
};

export default injectIntl(Labels);
