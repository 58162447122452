import { Fragment } from "react";
import getMessage from "../../utils/getMessage";

const RequiredAsterisk = () => {
  const requiredText = getMessage('required');

  return (
    <Fragment>
      <span title={requiredText} aria-hidden="true">*</span>
      <span className="sr-only">{`(${requiredText})`}</span>
    </Fragment>
  )
}

export default RequiredAsterisk;