import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import Button from 'react-bootstrap/lib/Button';

import config from '../../config';
import { getUser } from '../../store/selectors/user';

//import 'hds-core';
import 'hds-core/lib/components/text-input/text-input.css';
import 'hds-core/lib/components/button/button.css';
import './styles.css';
import getMessage from '../../utils/getMessage';
import { localizedNotifyError } from '../../utils/notify';
import { getLinkToBackend } from '../../api';
import { Helmet } from 'react-helmet';
import RequiredAsterisk from '../../components/forms/RequiredAsterisk';

const Login = ({user, language, router}) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loginError, setLoginError] = useState(null)

  const onSubmit = async e => {
    const params = new URLSearchParams(window.location.search);
    e.preventDefault();
    try {
      const data =
        username.includes('@')
          ? {
            email: username,
            password: password,
          }
          : {
            username,
            password,
          };
      const result = await fetch(`${config.apiBaseUrl}/v1/auth/login/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const response = await result.json();
      if (result.status === 400) {
        if (response.non_field_errors.includes('E-mail is not verified.')) {
          localizedNotifyError('loginFailUnverified');
          setLoginError('loginFailUnverified');
        } else {
          localizedNotifyError('loginFail');
          setLoginError('loginFail');
        }
      }
      if (response.token) {
        localStorage.setItem('token', response.token);
        const next = params.get('next');
        const addLanguageToUrl = (toString) => {
          return toString.includes('?')
            ? toString + `&lang=${language}`
            : toString + `?lang=${language}`
        }
        if (next && !next.includes('.') && !next.includes(':')) {
          window.open(addLanguageToUrl(next), '_self');
        } else {
          window.open(addLanguageToUrl('/'), '_self');
        }
      }
    } catch (e) {
      localizedNotifyError('loginFail');
      setLoginError('loginFail')
      throw new Error('Couldn\'t log in.');
    }
  };

  const onChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    switch(name) {
      case "username": setUsername(value); break;
      case "password": setPassword(value); break;
    }
  };

  const getRegistrationButton = () => {
    if (user) {
      return null;
    }
    return [
      <FormattedMessage
        key="signup"
        id="signup"
      >
        {signup => (
          <Link
            className="digi-button"
            to={`/auth/registration?lang=${language}`}
          >
            <span>{signup}</span>
          </Link>
        )}
      </FormattedMessage>,
    ];
  }

  const getMpassIdLoginButton = () => {
    if (!config.enableMPASSid || user) {
      return null;
    }
    return [
      <FormattedMessage
        key="signup"
        id="signup"
      >
        {signup => (
          <Link
            className="digi-button"
            to={getLinkToBackend('/oidc/authenticate')}
          >
            <img
              src={'https://wiki.eduuni.fi/download/attachments/262275211/Symboli.png?version=1&modificationDate=1646230697337&api=v2'}
              alt={'MPASSid logo'}
              style={{ height: "20px", marginRight: "5px"}}
            />
            <span><FormattedMessage id="loginUsingMPASSid" /></span>
          </Link>
        )}
      </FormattedMessage>,
    ];
  }

  
  const registrationButton = getRegistrationButton();
  const mpassIdLoginButton = getMpassIdLoginButton();

  return (
    <form
      className="hds-form hds-form--login"
      onSubmit={onSubmit}
    >
      <Helmet title={getMessage('login')} />
      <h1><FormattedMessage id='login'/></h1>
      <div className="hds-card">
        <div role="alert" aria-atomic="true">
          { loginError && <FormattedMessage id={loginError} /> }
        </div>
        <fieldset className="hds-fieldset">
          <div className="hds-text-input">
            <label htmlFor="username">
              {getMessage('username/email')}<RequiredAsterisk />
            </label>
            <div className="hds-text-input__input-wrapper">
              <input
                required
                id="username"
                name="username"
                className="form-control"
                type="text"
                onChange={onChange}
              />
            </div>
          </div>
          <div className="hds-text-input">
            <label htmlFor='password'>
              {getMessage('password')}<RequiredAsterisk />
            </label>
            <div className="hds-text-input__input-wrapper">
              <input
                required
                id="password"
                name="password"
                className="form-control"
                type="password"
                onChange={onChange}
              />
            </div>
          </div>
          <div className="space-between">
            <button
              type="submit"
              className="digi-button"
            >
              <span className="hds-button__label">{getMessage('loginToSystem')}</span>
            </button>
            <div className="align-right">
              <Link to={`/password-reset?lang=${language}`}>
                {getMessage('didForgotPassword')}
              </Link> 
            </div>
          </div>
        </fieldset>
        {mpassIdLoginButton}
        {registrationButton}
      </div>
    </form>
  );
  


}

export default withRouter(connect(state => ({
  user: getUser(state), // User dropdown requires this state
  language: state.language, // Language switch requires this state
  router: state.router, // Navigation activity requires this state
}))(Login));