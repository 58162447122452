import { getUser } from '../../store/selectors/user';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Box, Tabs } from '@mui/material';
import getMessage from '../../utils/getMessage';
import React, { cloneElement, Fragment, useEffect, useState } from 'react';
import { TabContext, TabPanel } from '@mui/lab';
import DigiraatiTabButton from './DigiraatiTabButton';
import useUserSummaryPanel from './useUserSummaryPanel.hook';
import { fetchInitialHearingList, fetchMoreHearings } from '../../store/actions';
import { hearingShape } from '../../types';

export const StyledTabs = styled(Tabs)`
    .MuiTabs-flexContainer {
      flex-direction: column;
    }
`;

export const TabStyle = {
  flexGrow: 1,
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-word',

};

const UserSummaryPanel = ({ user, fetchOwnHearingList, hearingLists }) => {

  const { tabs, params, getTabListByTabValue, isVotedCommentsLoading, fetchMoreVotedComments } = useUserSummaryPanel();
  const [value, setValue] = useState(tabs(user)[0].tabValue);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchOwnHearingList(params);
  }, []);

  useEffect(() => {
    setIsLoading(hearingLists.ownHearings.isFetching);
  }, [hearingLists.ownHearings.isFetching]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Fragment>
      <div>
        <h2 style={{textAlign: 'center'}}><FormattedMessage id='userInfoSummary'/></h2>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
            <div>
              <StyledTabs
                value={value}
                aria-label={getMessage('userInfoSummary')}
                TabIndicatorProps={{
                  style: {
                    visibility: 'hidden',
                  },
                }}
              >
                {tabs(user).map(tab =>
                  <DigiraatiTabButton
                    tabLabel={`${getMessage(tab.name)} (${tab.value})`}
                    isSelected={value === tab.tabValue}
                    key={`tab_${tab.tabValue}`}
                    value={tab.tabValue}
                    style={TabStyle}
                    handleChange={handleChange} />,
                )}
              </StyledTabs>
            </div>
            <div style={{flexGrow: 2, flexBasis: '700px'}}>
            {tabs(user).map(tab =>
              <TabPanel
                key={`tab_panel_${tab.tabValue}`}
                value={tab.tabValue}
                style={{ padding: '0' }}
              >
                {cloneElement(tab.component, {
                  value: tab.value,
                  index: tab.tabValue,
                  list: getTabListByTabValue(tab.tabValue, hearingLists?.ownHearings?.data),
                  handleReachBottom: fetchMoreHearings,
                  isLoading: tab.tabValue === 'votedSectionComments' ? isVotedCommentsLoading : isLoading,
                  header: tab.name,
                  fetchMore: tab.fetchMore
                })}
              </TabPanel>,
            )}
            </div>
            </div>
          </TabContext>
        </Box>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: getUser(state),
  hearingLists: state.hearingLists,
});
const mapDispatchToProps = dispatch => ({
  fetchOwnHearingList: (params) =>
    dispatch(fetchInitialHearingList('ownHearings', '/v1/hearing/', params)),
  fetchMoreHearings: () => dispatch(fetchMoreHearings('ownHearings')),
});

UserSummaryPanel.propTypes = {
  user: PropTypes.object,
  fetchOwnHearingList: PropTypes.func,
  hearingLists: PropTypes.objectOf(
    PropTypes.shape({
      isFetching: PropTypes.bool,
      data: PropTypes.arrayOf(hearingShape),
    }),
  ),
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(UserSummaryPanel));