import { FormattedMessage, injectIntl } from 'react-intl';
import React, { cloneElement, useState } from 'react';
import { Box, Tabs } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import getMessage from '/src/utils/getMessage';
import UserMainInfoPanel from './UserMainInfoPanel';
import UserSummaryPanel, { TabStyle } from './UserSummaryPanel';
import DigiraatiTabButton from './DigiraatiTabButton';
import styled from 'styled-components';
import PasswordChangePanel from './PasswordChangePanel';
import { Helmet } from 'react-helmet';

export const StyledTabs = styled(Tabs)`
    .MuiTabs-flexContainer {
        @media (max-width: 600px) {
            flex-direction: column;
        }

    }
`;

const components = [
  {
    tabIndex: "0",
    tabLabel: 'userInfoSummary',
    component: <UserSummaryPanel />,
  },
  {
    tabIndex: "1",
    tabLabel: 'userMainInfoTab',
    component: <UserMainInfoPanel />,
  },
  {
    tabIndex: "2",
    tabLabel: 'changePasswordTab',
    component: <PasswordChangePanel/>,
  },
];

const StyledTabPane = styled(TabPanel)`
    padding-left: 0;
    padding-right: 0;

    @media (max-width: 500px) {
        padding: 0;
    }
`;


const UserInfoPage = () => {

  const [value, setValue] = useState("0");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="user-info-page">
      <Helmet title={getMessage('userInfoPageHeader')} />

      <div>
        <div
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          <h1>
            <FormattedMessage id="userInfoPageHeader" />
          </h1>
        </div>
        <Box sx={{ maxWidth: '90vw', typography: 'body1', marginRight: '10px' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <StyledTabs
                value={value}
                TabIndicatorProps={{
                  style: {
                    visibility: 'hidden',
                  },
                }}
              >
                {components.map(c =>
                  <DigiraatiTabButton
                    tabLabel={getMessage(c.tabLabel)}
                    isSelected={value === c.tabIndex}
                    key={c.tabIndex}
                    value={c.tabIndex}
                    style={TabStyle}
                    handleChange={handleChange}
                  />,
                )}
              </StyledTabs>
            </Box>
            {components.map(item =>
              <StyledTabPane
                key={item.tabIndex}
                value={""+item.tabIndex}
              >
                {cloneElement(item.component, { value: item.tabIndex, index: item.tabIndex })}
              </StyledTabPane>,
            )}
          </TabContext>
        </Box>
      </div>
    </div>
  );
};

export default injectIntl(UserInfoPage);
