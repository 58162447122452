import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Icon from '../../utils/Icon';
import Link from '../LinkWithLang';
import MouseOnlyLink from '../MouseOnlyLink';
import config from '../../config';
import getAttr from '../../utils/getAttr';
import { getHearingMainImageURL, getHearingURLPreferCommentsIfAccess } from '../../utils/hearing';
// import person from "../../assets/images/person.svg";
import HearingJoinRequestInfo from './HearingJoinRequestInfo';
import nl2br from 'react-nl2br';

// eslint-disable-next-line import/no-unresolved
import defaultImage from '@city-images/default-image.svg';
import HearingCardLabels from './HearingCardLabels';
import { Badge } from '@mui/material';
import styled from 'styled-components';
import HearingCardInformation from './HearingCardInformation';
import { connect } from 'react-redux';
import { getUser } from '../../store/selectors/user';
import HearingInformationJoinRequests from './HearingInformationJoinRequests';
import NewTabLink from '../../common/NewTabLink';
import HeadingVariable from '../../common/HeadingVariable';

export const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    font: 'normal normal normal 16px PT Sans',
  },
}));

const HearingCard = ({
  hearing, 
  user, 
  language, 
  className = '', 
  history,
  showButton = true,
  headingLevel = 3,
}) => {
  const backgroundImage = getHearingMainImageURL(hearing);
  const cardImageStyle = {
    backgroundImage: backgroundImage
      ? `url(${backgroundImage})`
      : `url(${defaultImage})`,
  };

  // FIXME: Should there be direct linking to hearing using certain language?
  const translationAvailable = !!getAttr(hearing.title, language, {
    exact: true,
  });
  const availableInLanguageMessages = {
    fi: 'Raati saatavilla suomeksi',
    sv: 'Panelen tillgängligt på svenska',
    en: 'Questionnaire available in English',
  };
  
  
  function getFinalStatementPreviewURL() {
    const finalStatementPreviewURL =
      '/statement/preview/' + hearing.final_statement.id;
    return finalStatementPreviewURL;
  }

  const participationStatusList = [
    {
      status: 'isOrgAdminOfHearing',
      condition: user?.adminOrganizations?.includes(hearing.organization)
    },
    {
      status: 'isFacilitatorOfHearing',
      condition: hearing.user_is_facilitator,
    },
    {
      status: 'isMemberOfHearing',
      condition: hearing.user_is_member
    },
    {
      status: 'isSuperUserOfHearing',
      condition: user?.is_superuser
    },
    {
      status: 'isNotMemberOfHearing',
      condition: true
    }
  ]

  const participationStatus = participationStatusList.find(status => status.condition).status

  const membershipStatusMessageId = !user 
    ? "youAreNotLoggedIn"
    : (hearing.can_access_detail 
        ? participationStatus
        : "isNotMemberOfHearing"
      )

  return (
    <li className={`hearing-card ${className}`}>
      <MouseOnlyLink
        className="hearing-card-image"
        style={cardImageStyle}
        history={history}
        url={getHearingURLPreferCommentsIfAccess(hearing)}
        altText={getAttr(
          hearing.main_image && hearing.main_image.caption[language]
            ? hearing.main_image.caption[language]
            : hearing.title,
          language,
        )}
      />
      <div className="hearing-card-content">
        <HeadingVariable level={headingLevel} className="h4 hearing-card-title" title={getAttr(hearing.title, language)}>
          {hearing.can_access_detail ? (
            <Link to={{ path: getHearingURLPreferCommentsIfAccess(hearing) }}>
              {getAttr(hearing.title, language)}
            </Link>
          ) : (
            getAttr(hearing.title, language)
          )}
        </HeadingVariable>
        <address className="hearing-organization">{hearing.organization}</address>
        {!translationAvailable && (
          <div className="hearing-card-abstract">
          <div className="hearing-card-notice">
            <Icon name="exclamation-circle" aria-hidden="true" />
            <div>
            <FormattedMessage id="hearingTranslationNotAvailable" />
            {config.languages.map(lang => {
              return getAttr(hearing.title, lang, { exact: true }) ? (
                <div className="language-available-message" key={lang} lang={lang}>
                  {availableInLanguageMessages[lang]}
                </div>
              ) : null;
            })}
            </div>
          </div>
          {nl2br(getAttr(hearing.abstract, language))}
          </div>
        )}
        {translationAvailable && (
          <p className='hearing-card-abstract'>{nl2br(getAttr(hearing.abstract, language))}</p>
        )

        }
        
        <HearingCardLabels hearing={hearing} language={language} />
      </div>
      <div className="hearing-card-info">
        <HearingCardInformation hearing={hearing} history={history} />
        {hearing.final_statement && hearing.final_statement.published && (
          <NewTabLink 
            to={getFinalStatementPreviewURL()} 
            textId='finalStatementPreview' 
            className="final-statement-preview-link"
          />
        )}
          {hearing.can_access_detail && hearing.active_join_requests_num > 0 &&
            <HearingInformationJoinRequests hearing={hearing} history={history} />
          }
      </div>
        <div style={{textAlign:'center', padding: '1em', paddingTop: '0', marginTop: 'auto',}}>
          <div>
            <FormattedMessage id={membershipStatusMessageId} />
          </div>
          {showButton && <HearingJoinRequestInfo hearing={hearing} listId="openHearings" />}
        </div>
    </li>
  );
};

HearingCard.propTypes = {
  className: PropTypes.string,
  hearing: PropTypes.object,
  language: PropTypes.string,
  history: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: getUser(state),
});

export default withRouter(connect(mapStateToProps)(HearingCard));
